import React, { useState, useEffect } from "react";
// Import Components
import { useTranslation } from "react-i18next";
import SectionHeading from "../SectionHeading/SectionHeading";
import { OurServiceData } from "./OurServicesData";
import OurServiceCard from "../OurService/OurServiceCard";
import { useLocation } from "react-router-dom";

const languages = [{ name: "en" }, { name: "bg" }];

const OurServices = () => {
	const location = useLocation();
	const currentPage = location.pathname;
	const [showResults, setShowResults] = React.useState(false);
	const [servicesList, setServicesList] = useState([]);
	const { i18n, t } = useTranslation(["header"]);

	useEffect(() => {
		currentPage == "/service_details/web-development"
			? setShowResults(true)
			: setShowResults(false);
	}, []);

	useEffect(() => {
		setServicesList((prev) => [...OurServiceData.all]);
	}, []);

	return (
		
		<>
			{/* Service Area Start */}
			<section id="service" className="bg-color">
				<div className="container">
					{showResults ? null : (
						<SectionHeading
							title={t("homepage.service.our-services")}
							heading={t("homepage.service.we-offer")}
						/>
					)}
					<div className="row">
						{servicesList.map((data, index) => (
							<OurServiceCard data={data} key={index} />
						))}
					</div>
				</div>
			</section>
			{/* Service Area End */}
		</>
	);
};

export default OurServices;
