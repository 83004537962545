import svg1 from '../../../assets/img/svg/data.svg'
import svg2 from '../../../assets/img/svg/check.svg'
import svg3 from '../../../assets/img/svg/monitor.svg'
export const BottomData =[
    {
        svg:svg1,
        heading:"Big Data Analytics",
        para:`Lorem ipsum, dolor sit amet consectetur adipisicing elit. eum maxime vel, fuga voluptate possimus doloremque sequi aliquid, veniam iure officia dolorem.`,
    }, 
    {
        svg:svg2,
        heading:"Big Data Intergration",
        para:`Lorem ipsum, dolor sit amet consectetur adipisicing elit. eum maxime vel, fuga voluptate possimus doloremque sequi aliquid, veniam iure officia dolorem.`,
    }, 
    {
        svg:svg3,
        heading:"Content Management",
        para:`Lorem ipsum, dolor sit amet consectetur adipisicing elit. eum maxime vel, fuga voluptate possimus doloremque sequi aliquid, veniam iure officia dolorem.`,
    },
    {
        svg:svg1,
        heading:"Big Data Analytics",
        para:`Lorem ipsum, dolor sit amet consectetur adipisicing elit. eum maxime vel, fuga voluptate possimus doloremque sequi aliquid, veniam iure officia dolorem.`,
    }
 ]