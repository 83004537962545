import React from "react";
import { MdPermPhoneMsg } from "react-icons/md";
import { ImLocation2 } from "react-icons/im";
import { BiMailSend } from "react-icons/bi";
import { useTranslation } from "react-i18next";
const ContactInfo = () => {
	const { i18n, t } = useTranslation(["header"]);
	return (
		<>
			<div className="contact-content">
				<h3>{t("homepage.contacts.get")}</h3>
				<p>
				{t("homepage.contacts.you")}
				</p>
				<ul className="contact-info">
					<li>
						<div className="media">
							<div className="icon-area">
								<i>
									<MdPermPhoneMsg />
								</i>
							</div>
							<div className="media-body contact-info-here">
								<h5>
									<a href="tel:+359 889 03 48 49">+359 889 03 48 49</a>
								</h5>
							</div>
						</div>
					</li>
					<li>
						<div className="media">
							<div className="icon-area">
								<i>
									<ImLocation2 />
								</i>
							</div>
							<div className="media-body contact-info-here">
								<h5><a href="https://maps.google.com/?q=Maria+Luiza+6-A,Plovdiv,Bulgaria&zoom=2" target="blank">{t("footer.address")}</a> </h5>
								
							</div>
						</div>
					</li>
					<li>
						<div className="media">
							<div className="icon-area">
								<i>
									<BiMailSend />
								</i>
							</div>
							<div className="media-body contact-info-here">
								<h5>
									<a href="mailto:office@ilievsoft.bg">office@ilievsoft.bg</a>
								</h5>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</>
	);
};

export default ContactInfo;
