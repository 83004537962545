import React from "react";
// Import ProjectCard
import ProjectCard from ".//ProjectCard";
// Import ProjectData
import ProjectData from "./ProjectData";

import { motion } from "framer-motion";



const OurProjects = () => {

	console.log(ProjectData);

	return (
		<>
			{/* Project-Grid Start */}
			<motion.section id="project-grid" className="py100">
				<motion.div className="container">
					<motion.div className="row">
						{ProjectData.map((data, index) => (
							<ProjectCard data={data} key={index} />
						))}
					</motion.div>
				</motion.div>
			</motion.section>
			{/* Project-Grid End */}
		</>
	);
};

export default OurProjects;
