import img1 from '../../../assets/img/team/t-1.png'
import img2 from '../../../assets/img/team/t-2.png'
import img3 from '../../../assets/img/team/t-3.png'
import img4 from '../../../assets/img/team/t-4.png'

export const TeamData = [
    {
        img: img1,
        name: "Kamelia Doe",
        title: "IT Executive"
    },
    {
        img: img2,
        name: "Kamelia Doe",
        title: "IT Executive"
    },
    {
        img: img3,
        name: "Kamelia Doe",
        title: "IT Executive"
    },
    {
        img: img4,
        name: "Kamelia Doe",
        title: "IT Executive"
    }
]
