import React,{useEffect,useState} from "react";
import { ImLocation } from "react-icons/im";
import { FiPhoneOutgoing } from "react-icons/fi";
import { SiMinutemailer } from "react-icons/si";
import { AiOutlineCheck } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useParams } from "react-router-dom";
import projectsList from "../Projects/ProjectData";

const Sidebar = () => {

	const { i18n, t } = useTranslation(["header"])
	const { id } = useParams();

	const [projectToDisplay, setProjectToDisplay] = useState({});
	const languages = [
		{ name: "en" },
		{ name: "bg"},]

	useEffect(() => {
		const current = projectsList.find((el) => el.id === id);
		setProjectToDisplay((prev) => ({ ...current }));
		i18n.changeLanguage(localStorage.getItem("i18nextLng"));
	}, []);
	
	return (
		<>
			<div className="ps-informations">
				<h3> {t("projects.details")}</h3>
				<ul>
					<li>
						<span>
							<i>
								<AiOutlineCheck />
							</i>
							{t("projects.category")}
						</span>{" "}
						{t(projectToDisplay.cat)}
					</li>
					<li>
						<span>
							<i>
								<AiOutlineCheck />
							</i>
							{t("projects.client")}
						</span>{" "}
						{t(projectToDisplay.client)}
					</li>
					<li>
						<span>
							<i>
								<AiOutlineCheck />
							</i>
							{t("projects.location")}
						</span>{" "}
						{t(projectToDisplay.country)}
					</li>
					<li>
						<span>
							<i>
								<AiOutlineCheck />
							</i>
							{t("projects.completed")}
						</span>{""}
						{t(projectToDisplay.date)}
					</li>
				</ul>
			</div>
			<div className="company-info">
				<h3> {t(projectToDisplay.moreabout)}</h3>
				<p>
				{t(projectToDisplay.impressclients)}
				
				</p>
				<a href={projectToDisplay.site} target="blank "className="btn btn-theme">{t("projects.gotoproject")}</a>
				{/* <a
					href={documentToDownload}
					download="doc_to_pdf"
					className="btn btn-theme"
				>
					Download PDF
				</a> */}
			</div>
			<div className="contact-widget">
				<h3>{t(projectToDisplay.contactsidebar)}</h3>
				<ul>
					<li>
						<i>
							{" "}
							<ImLocation />
						</i>{" "}
						<a href={projectToDisplay.location} target="blank " >{t(projectToDisplay.addresslocation)} </a>
					</li>
					<li>
						<i>
							<FiPhoneOutgoing />
						</i>
						<a href ="tel: +49 (0)6441 2080 - 0">{t(projectToDisplay.phone)}</a>						
					</li>
					<li>
						<i>
							{" "}
							<SiMinutemailer />{" "}
						</i>
						<a href="mailto:info@leica-camera.com ">{t(projectToDisplay.address)}</a>
						
					</li>
				</ul>
			</div>
		</>
	);
};

export default Sidebar;
