import React from "react";
// Import Img
import small1 from "../../../assets/img/small-icon/1.png";
import small2 from "../../../assets/img/small-icon/2.png";
import small3 from "../../../assets/img/small-icon/3.png";
import AboutImg from "../../../assets/img/about/2.png";
import awesomefeatures from "../../../assets/img/awesomefeatures.png";
import { useTranslation } from "react-i18next";

const AboutTwo = () => {
	const { i18n, t } = useTranslation(["header"]);
	return (
		<>
			{/* About Two Area Start */}
			<section id="about" className="py100">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="animation-img animation-img-one">
								<img alt="awesomefeatures.png" src={awesomefeatures} />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="right-side-about">
								<h2> {t("homepage.web-development.custom-web-development")}</h2>
								<div className="right-area-about-list">
									<div className="media">
										<img alt="" className="mr-3" src={small1} />
										<div className="media-body">
											<h3 className="mt-0">
												{t("homepage.web-development.get-a-quick-quote")}
											</h3>
											<p>{t("homepage.web-development.website-to-build")}</p>
										</div>
									</div>
									<div
										className="media  media wow fadeInUp "
										data-wow-duration="2.0s "
										data-wow-delay=".2s "
									>
										<img alt="" className="mr-3 " src={small2} />
										<div className="media-body ">
											<h3 className="mt-0 ">
												{" "}
												{t(
													"homepage.web-development.Already-have-a-website"
												)}{" "}
											</h3>
											<p>{t("homepage.web-development.running-website")}</p>
										</div>
									</div>
									<div
										className="media  media wow fadeInUp"
										data-wow-duration="2.0s"
										data-wow-delay=".3s"
									>
										<img alt="" className="mr-3" src={small3} />
										<div className="media-body">
											<h3 className="mt-0">
												{t("homepage.web-development.better-ask-the-experts")}
											</h3>
											<p>{t("homepage.web-development.expert-consultation")}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About Two Area End */}
		</>
	);
};

export default AboutTwo;
