import React from 'react'
import img from '../../assets/img/project-details/2.png'
import { useTranslation } from "react-i18next";

const SingleServiceDeatils = () => {
    const { i18n, t } = useTranslation(["header"]);
    return (
        <>
            <section id="service-img-details" className="py100">
                <div className="container">
                    <div className="row">
                      
                
                
                            <div className="col-lg-6">
                            <div className="service-img-text">
                               
                               
                                <ul>
                                <h3>{t("salesforce.title1")}</h3>
                                <p>{t("salesforce.text1")}</p>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-img-text">
                               
                               
                                <ul>
                                <h3>{t("salesforce.title3")}</h3>
                                <p>{t("salesforce.text3")}</p>
                                </ul>
                            </div>
                        </div>
                        </div>
                        </div>
                        <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="service-img-text">
                               
                               
                                <ul>
                                <h3>{t("salesforce.title2")}</h3>
                                <p>{t("salesforce.text2")}</p>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-img-text">
                               
                               
                                <ul>
                                <h3>{t("salesforce.title4")}</h3>
                                <p>{t("salesforce.text4")}</p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default SingleServiceDeatils
