import React from 'react'
import img from '../../assets/img/project-details/2.png'
import { useTranslation } from "react-i18next";

const SingleWebDevelopmentDeatils = () => {
    const { i18n, t } = useTranslation(["header"]);
    return (
        <>
            <section id="service-img-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="service-img-details">
                                <img alt="" src={img} />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="service-detail-text">
                                <div className="left-side-title">
                                    <h2 className="pt10">{t("web-development.title")}</h2>
                                    <p>{t("web-development.short_description1")}</p>
                                    <p>{t("web-development.short_description2")}</p>
                                </div>
                            </div>
                        </div>
                
                
                           
                    </div>
                </div>
            </section>
            
        </>
    )
}

export default SingleWebDevelopmentDeatils
