import React from 'react'
import {AiOutlineUsergroupAdd} from "react-icons/ai";
import {BiCodeAlt} from "react-icons/bi";
import {FaAward} from "react-icons/fa";
import CountUp from 'react-countup';
import { useTranslation } from "react-i18next";
const Index = () => {
    const { i18n, t } = useTranslation(["header"]);
    return (
        <>
            <section id="counter-area" className="py100 bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="counter-area">
                                <div className="counter-icon">
                                    <i><AiOutlineUsergroupAdd/></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter"><CountUp end={100}/></h2>
                                    <h4> {t("homepage.stats.satisfied")} </h4>
                                    <p>{t("homepage.stats.satisfiedtext")} </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="counter-area responsive-md">
                                <div className="counter-icon">
                                    <i><BiCodeAlt/></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter"><CountUp end={200}/></h2>
                                    <h4>{t("homepage.stats.completed")} </h4>
                                    <p>{t("homepage.stats.completedtext")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="counter-area responsive-sm">
                                <div className="counter-icon">
                                    <i><FaAward/></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter"><CountUp end={100}/></h2>
                                    <h4>{t("homepage.stats.quality")}</h4>
                                    <p>{t("homepage.stats.qualitytext")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Index
