import React from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

import styled from "styled-components";

const WorkItem = (props) => {
	const { ratio, img, id, bg, imgBg } = props;

	return (
		<WorkItemContainer className="work-item" ratio={ratio} bg={bg}>
			<img alt="" src={img} />
			<div className="overlay-arae">
				<Link to={`/project_details/${id}`}>
					{" "}
					<i>
						<BsArrowRight />
					</i>
				</Link>
			</div>

			{imgBg && <img className="img-bg" src={imgBg} alt="" />}
		</WorkItemContainer>
	);
};

const WorkItemContainer = styled.div`
	background-color: ${({ bg }) => (bg ? bg : null)};
	position: relative;

	img {
		/* aspect-ratio: ${({ ratio }) => ratio}; */
		object-fit: cover;
		z-index: 2;
	}

	.img-bg {
		position: absolute;
		z-index: -10;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

export default WorkItem;
