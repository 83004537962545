import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import { motion } from "framer-motion";
const ProjectCard = ({ data }) => {
	return (
		<>
			{/* Project Area Start */}
			<StyledProjectCard className="col-lg-4 col-md-6 col-ms-12 col-12">
				<Link to={`/project_details/${data.id}`}>
					<motion.div className="project-grid-item">
						<motion.div className="project-grid-img">
							<motion.img
								alt="project photo"
								src={data.img}
								style={{
									backgroundColor: data.bg,
								}}
							/>

							{data.imgBg && (
								<motion.img
									alt="project photo"
									src={data.imgBg}
									style={{
										backgroundColor: data.bg,
									}}
									className={"imgBg"}
								/>
							)}
						</motion.div>
						<motion.div className="project-grid-content">
							<motion.h5>{data.title}</motion.h5>
						</motion.div>
					</motion.div>
				</Link>
			</StyledProjectCard>
			{/* Project Area End */}
		</>
	);
};

const StyledProjectCard = styled(motion.div)`
	.project-grid-item {
		position: relative;
	}

	& .project-grid-item img {
		box-shadow: var(--greyOne);
		transition: all 0.2s ease-in-out;
		position: relative;
		z-index: 2;
	}

	& .project-grid-item img.imgBg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		z-index: 1 !important;
	}

	& h5 {
		border-radius: 3px !important;
		box-shadow: var(--whiteOne);
		font-weight: 300;
		font-family: "Open sans", sans-serif;
		background: var(--main-theme-color);
		padding: 7px 14px;
		color: #fff;
		margin: 0;
	}

	& a:hover .project-grid-item img {
		box-shadow: none;
	}
`;

export default ProjectCard;
