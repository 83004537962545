import { v4 } from "uuid";

import leica from "../../assets/img/custom/projects-page/leica.jpg";
import llogoleica from "../../assets/img/llogoleica.png";
import daimler from "../../assets/img/custom/projects-page/daimler.jpg";
import daimler1 from "../../assets/img/daimler1.jpg";
import vb from "../../assets/img/custom/projects-page/vb.jpg";
import vb1 from "../../assets/img/vb1.jpg";
import hella from "../../assets/img/custom/projects-page/hella.jpg";
import hella1 from "../../assets/img/hella1.jpg";
import westfalen from "../../assets/img/custom/projects-page/westfalen.jpg";
import westfalen1 from "../../assets/img/westfalen1.jpg";
import zonia from "../../assets/img/custom/projects-page/zonia.jpg";
import zonia1 from "../../assets/img/zonia1.jpg";
import laprairie from "../../assets/img/custom/projects-page/laprairie.jpg";
import la from "../../assets/img/custom/projects-page/laprairie.jpg";
import bf from "../../assets/img/custom/projects-page/boosting-factory.png";
import boostingProjectBg from "../../assets/img/custom/projects-page/boosting-project-bg.jpg";
// import validis from "../../assets/img/custom/projects-page/validis.jpg";
// import cocaCola from "../../assets/img/custom/projects-page/coca-cola.jpg";
// import zurich from "../../assets/img/custom/projects-page/zurich.jpg";
// import roche from "../../assets/img/custom/projects-page/roche.jpg";
// import sika from "../../assets/img/custom/projects-page/sika.jpg";
// import globalinternet from "../../assets/img/custom/projects-page/globalinternet.jpg";
// import sonova from "../../assets/img/custom/projects-page/sonova.jpg";
// import lidl from "../../assets/img/custom/projects-page/lidl.jpg";
// import rm from "../../assets/img/custom/projects-page/rm.jpg";
// import amp from "../../assets/img/custom/projects-page/amp.jpg";

export default [

	{ 
		id: "leica", 
		img: leica, 
		img2:llogoleica,
		site: "https://club.leica-camera.com",
		location:"https://www.google.com/maps/search/?q=Am,Leitz-Park,5,35578,Wetzlar,Germany",
		title: "Leica",
		ratio: "19/8",
		discovered : "projects.leica.discovered" ,
		quote:"projects.leica.quote",
		secondp:"projects.leica.secondp",
		cat:"projects.leica.sidebar.cat",
		client:"projects.leica.sidebar.client",
		country:"projects.leica.sidebar.country",
		date:"projects.leica.sidebar.date",
		moreabout:"projects.moreabout",
		impressclients:"projects.impressclients",
		contactsidebar:"projects.contact-sidebar",
		address:"info@leica-camera.com" ,
		addresslocation:"Am Leitz-Park 5, 35578 Wetzlar, Germany",
		phone:"+49 (0)6441 2080 - 0",
		a1:"projects.leica.a1",
		a2:"projects.leica.a2",
		a3:"projects.leica.a3",
		a4:"projects.leica.a4",
		a5:"projects.leica.a5",
		a6:"projects.leica.a6"
		
		
		

	},
	{ 
		id: "daimler-ag", 
		img: daimler, 
		img2:daimler1,
		site: "https://club.leica-camera.com",
	    title: "Daimler AG", 
		ratio: "9/8",
		discovered : "projects.daimler-ag.discovered" ,
		quote:"projects.daimler-ag.quote",
		secondp:"projects.daimler-ag.secondp",
		cat:"projects.daimler-ag.sidebar.cat",
		client:"projects.daimler-ag.sidebar.client",
		country:"projects.daimler-ag.sidebar.country",
		date:"projects.daimler-ag.sidebar.date",
		moreabout:"projects.moreabout",
		impressclients:"projects.impressclients",
		contactsidebar:"projects.contact-sidebar",
		address:"dialog@daimler.com",
		addresslocation:"Stuttgart, Baden-Württemberg, Germany",
		phone:"+49 711 17 0",
		a1:"projects.daimler-ag.a1",
		a2:"projects.daimler-ag.a2",
		a3:"projects.daimler-ag.a3",
		a4:"projects.daimler-ag.a4",
		a5:"projects.daimler-ag.a5",
		a6:"projects.daimler-ag.a6"

	},
	{ 
		id: "villeroy-&-boch", 
		img: vb,
		img2:vb1,
		site: "https://www.villeroy-boch.co.uk/",
		title: "Villeroy & Boch", 
		ratio: "9/8",
		discovered : "projects.villeroy-&-boch.discovered" ,
		quote:"projects.villeroy-&-boch.quote",
		secondp:"projects.villeroy-&-boch.secondp",
		cat:"projects.villeroy-&-boch.sidebar.cat",
		client:"projects.villeroy-&-boch.sidebar.client",
		country:"projects.villeroy-&-boch.sidebar.country",
		date:"projects.villeroy-&-boch.sidebar.date",
		moreabout:"projects.moreabout",
		contactsidebar:"projects.contact-sidebar",
		impressclients:"projects.impressclients",
		address:"VB.Support@saragroup.com",
		addresslocation:"82, rue d'Hauteville . F-75010 Paris",
		phone:"00800 6864 8110",
		a1:"projects.villeroy-&-boch.a1",
		a2:"projects.villeroy-&-boch.a2",
		a3:"projects.villeroy-&-boch.a3",
		a4:"projects.villeroy-&-boch.a4",
		a5:"projects.villeroy-&-boch.a5",
		a6:"projects.villeroy-&-boch.a6"

	},
	{ 
		id: "hella", 
		img: hella, 
		img2:hella1,
		site:"https://www.hella.com/hella-com/en/index.html",
		title: "Hella", 
		ratio: "9/8",
		discovered : "projects.hella.discovered" ,
		quote:"projects.hella.quote",
		secondp:"projects.hella.secondp",
		cat:"projects.hella.sidebar.cat",
		client:"projects.hella.sidebar.client",
		country:"projects.hella.sidebar.country",
		date:"projects.hella.sidebar.date",
		moreabout:"projects.moreabout",
		contactsidebar:"projects.contact-sidebar",
		impressclients:"projects.impressclients",
		address:"webedi@hella.com",
		addresslocation:"HELLA Standort Lippstadt 700",
		phone:"01295 662400",
		a1:"projects.hella.a1",
		a2:"projects.hella.a2",
		a3:"projects.hella.a3",
		a4:"projects.hella.a4",
		a5:"projects.hella.a5",
		a6:"projects.hella.a6"

	},
	{ 
		id: "westfalen-ag", 
		img: westfalen, 
		img2:westfalen1,
		site: "https://westfalen.com/de/de/privatkunden",
		title: "Westfalen AG", 
		ratio: "19/8",
		discovered : "projects.westfalen-ag.discovered" ,
		quote:"projects.westfalen-ag.quote",
		secondp:"projects.westfalen-ag.secondp",
		cat:"projects.westfalen-ag.sidebar.cat",
		client:"projects.westfalen-ag.sidebar.client",
		country:"projects.westfalen-ag.sidebar.country",
		date:"projects.westfalen-ag.sidebar.date",
		moreabout:"projects.moreabout",
		contactsidebar:"projects.contact-sidebar",
		impressclients:"projects.impressclients",
		address:"info(at)westfalen.com",
		addresslocation:"Industrieweg 4348155 Münster",
		phone:"+49 (0)5459 806-25",
		a1:"projects.westfalen-ag.a1",
		a2:"projects.westfalen-ag.a2",
		a3:"projects.westfalen-ag.a3",
		a4:"projects.westfalen-ag.a4",
		a5:"projects.westfalen-ag.a5",
		a6:"projects.westfalen-ag.a6"

	},
	{
		id: "zonia",
		img: zonia,
		img2: zonia1,
		site:"https://zonia.com/",
		title: "Zonia",
		ratio: "9/8",
		discovered : "projects.zonia.discovered",
		quote:"projects.zonia.quote",
		secondp:"projects.zonia.secondp",
		cat:"projects.zonia.sidebar.cat",
		client:"projects.zonia.sidebar.client",
		country:"projects.zonia.sidebar.country",
		date:"projects.zonia.sidebar.date",
		moreabout:"projects.moreabout",
		contactsidebar:"projects.contact-sidebar",
		impressclients:"projects.impressclients",
		address:"support@zonia",
		addresslocation:"Pier 7, 2nd Floor, Dubai Marina, UAE.",
		phone:"+1 (310) 844 1994",
		a1:"projects.zonia.a1",
		a2:"projects.zonia.a2",
		a3:"projects.zonia.a3",
		a4:"projects.zonia.a4",
		a5:"projects.zonia.a5",
		a6:"projects.zonia.a6",
		a7:"projects.zonia.a7",
		a8:"projects.zonia.a8",
		a9:"projects.zonia.a9",
		a10:"projects.zonia.a10",
		a11:"projects.zonia.a11"

	},
	{
		id: "boosting-factory",
		img: boostingProjectBg,
		img2: bf,
		site:"https://www.boostingfactory.com/",
		title: "Boosting Factory",
		ratio: "19/8",
		discovered : "projects.boosting-factory.discovered",
		quote:"projects.boosting-factory.quote",
		secondp:"projects.boosting-factory.secondp",
		cat:"projects.boosting-factory.sidebar.cat",
		client:"projects.boosting-factory.sidebar.client",
		country:"projects.boosting-factory.sidebar.country",
		date:"projects.boosting-factory.sidebar.date",
		moreabout:"projects.moreabout",
		contactsidebar:"projects.contact-sidebar",
		impressclients:"projects.impressclients",
		address:"support@boostingfactory.com",
		addresslocation:"Petrova 118, 10000 Zagreb, Croatia",
		phone:"+385958559292",
		a1:"projects.boosting-factory.a1",
		a2:"projects.boosting-factory.a2",
		a3:"projects.boosting-factory.a3",
		a4:"projects.boosting-factory.a4",
		a5:"projects.boosting-factory.a5",
		a6:"projects.boosting-factory.a6",
		a7:"projects.boosting-factory.a7",
		a8:"projects.boosting-factory.a8",
		a9:"projects.boosting-factory.a9",
		a10:"projects.boosting-factory.a10",
		a11:"projects.boosting-factory.a11",
		a12:"projects.boosting-factory.a12"

	},
	{
		id: "laprairie",
		img: laprairie,
		img2: la,
		site:"https://www.laprairie.com/en-int/?nogeoip=true",
		title: "LaPrairie",
		//bg: "#BB945A",
		ratio: "9/8",
		discovered : "projects.laprairie.discovered",
		quote:"projects.laprairie.quote",
		secondp:"projects.laprairie.secondp",
		cat:"projects.laprairie.sidebar.cat",
		client:"projects.laprairie.sidebar.client",
		country:"projects.laprairie.sidebar.country",
		date:"projects.laprairie.sidebar.date",
		moreabout:"projects.moreabout",
		contactsidebar:"projects.contact-sidebar",
		impressclients:"projects.impressclients",
		address:"press@laprairie.com",
		addresslocation:"Lafayette, Coupole. 18, Rue De La Chaussee D'Antin, 75009, Paris",
		phone:"+ 1 800 821 5718",
		a1:"projects.laprairie.a1",
		a2:"projects.laprairie.a2",
		a3:"projects.laprairie.a3",
		a4:"projects.laprairie.a4",
		a5:"projects.laprairie.a5",
		a6:"projects.laprairie.a6",

	},
];


/* original data */

// import img1 from '../../assets/img/project-grid/1.jpg'
// import img2 from '../../assets/img/project-grid/2.jpg'
// import img3 from '../../assets/img/project-grid/3.jpg'

// export const ProjectData = [
//     {
//         img: img1,
//         title: "Artificial Intelligence"
//     },
//     {
//         img: img2,
//         title: "Web Design"
//     },
//     {
//         img: img3,
//         title: "App Design"
//     },
//     {
//         img: img2,
//         title: "Web Application"
//     },
//     {
//         img: img3,
//         title: "App Development"
//     },
//     {
//         img: img1,
//         title: " SEO "
//     },
//     {
//         img: img1,
//         title: "App Design"
//     },
//     {
//         img: img2,
//         title: "Web Design"
//     },

//     {
//         img: img3,
//         title: "Web Application"
//     }
// ]
