import React, { useEffect } from "react";
import shap from "../assets/img/svg/footer.svg";
import { Link } from "react-router-dom";
import logo from "../assets/img/custom/iliev_soft_logо.png";
import { ImLocation, ImSkype } from "react-icons/im";
import { FiPhoneOutgoing } from "react-icons/fi";
import { SiMinutemailer } from "react-icons/si";

import { useTranslation } from "react-i18next";

const FooterItems = [
	{
		title: "footer.useful-title",
		links: [
			{ linkTitle: "footer.useful-links.home-title", link: "/" },
			{ linkTitle: "footer.useful-links.about-title", link: "/about" },
			{ linkTitle: "footer.useful-links.contact-us-title", link: "/contact" },
			{ linkTitle: "footer.useful-links.projects-title", link: "/projects" },
		],
	},
	{
		title: "footer.services-title",
		links: [
			{
				linkTitle: "footer.services-links.salesforce",
				link: "/service_details/salesforce",
			},
			{
				linkTitle: "footer.services-links.web-development",
				link: "/service_details/web-development",
			},
		],
	},
];
const languages = [{ name: "en" }, { name: "bg" }];
const Footer = () => {
	const { i18n, t } = useTranslation(["header"]);

	useEffect(() => {
		i18n.changeLanguage(localStorage.getItem("i18nextLng"));
	}, []);

	return (
		<>
			<footer id="footer" className="py100">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<div className="footer-about-area">
								<Link to="/">
									<img src={logo} alt="" />
								</Link>
								<p className="pt30">{t("footer.it-science-company")}</p>
								<p>{t("footer.text-fusion")}</p>
							</div>
						</div>
						{FooterItems.map((item, key) => (
							<div className="col-lg-2 col-md-6 col-sm-6 col-6" key={key}>
								<div className="footer-list-widget pl20 single-item-mt-3 responsive-mt-60">
									<div className="footer-heading">
										<h5>{t(item.title)}</h5>
									</div>
									<div className="footer-list pt40">
										<ul>
											{item.links.map((flinks, key) => (
												<li key={key}>
													<Link to={flinks.link}>{t(flinks.linkTitle)}</Link>
												</li>
											))}
										</ul>
									</div>
								</div>
							</div>
						))}
						<div className="col-lg-4 col-md-6 col-sm-12 col-12">
							<div className="footer-list-widget pl20 responsive-mt-60">
								<div className="footer-heading">
									<h5>{t("footer.contact-info")}</h5>
								</div>
								<div className="footer-contact-area footer-list pt40">
									<ul>
										<li>
											<i>
												<FiPhoneOutgoing />
											</i>
											<a href="tel:+359889034849">+359 889 03 48 49</a>
										</li>

										<li>
											<i>
												<SiMinutemailer />
											</i>
											<a href="mailto:office@ilievsoft.bg">
												office@ilievsoft.bg
											</a>
										</li>

										<li>
											<i>
												<ImSkype />
											</i>
											<a href="skype:IlievSoft?chat"> IlievSoft</a>
										</li>

										<li>
											<i>
												<ImLocation />
											</i>{" "}
											<a
												href="https://www.google.com/maps/search/?q=MariaLuiza6A,Plovdiv,Bulgaria"
												target="blank"
											>
												{t("footer.address")}
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom-img">
					<img alt="" src={shap} />
				</div>
			</footer>
		</>
	);
};

export default Footer;
