// import logo1 from "../../../assets/img/logo/1.png";
// import logo2 from "../../../assets/img/logo/2.png";
// import logo3 from "../../../assets/img/logo/3.png";
// import logo4 from "../../../assets/img/logo/4.png";
// import logo5 from "../../../assets/img/logo/5.png";

// export const PartnerData = [
// 	{
// 		img: logo1,
// 	},
// 	{
// 		img: logo2,
// 	},
// 	{
// 		img: logo3,
// 	},
// 	{
// 		img: logo4,
// 	},
// 	{
// 		img: logo5,
// 	},
// ];

import logo1 from "../../../assets/img/custom/slider/logo1.png";
import logo2 from "../../../assets/img/custom/slider/logo2.png";
import logo3 from "../../../assets/img/custom/slider/logo3.jpg";
import logo4 from "../../../assets/img/custom/slider/logo4.jpg";
import logo5 from "../../../assets/img/custom/slider/logo5.jpg";
import logo6 from "../../../assets/img/custom/slider/logo6.jpg";

export const PartnerData = [
	{ img: logo1 },
	{ img: logo2 },
	{ img: logo3 },
	{ img: logo4 },
	{ img: logo5 },
	{ img: logo6 },
];
