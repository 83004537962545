import { useState } from "react";

const useForm = (callback, initVlues) => {
	const [values, setValues] = useState(initVlues);

	const onInputChange = (e) => {
		const currentInputName = e.target.name;
		const currentInputValue = e.target.value;

		setValues((prev) => ({
			...prev,
			[currentInputName]: currentInputValue,
		}));
	};

	const onSubmit = (e) => {
		e.preventDefault();

		callback();

		e.target.reset();
	};

	return { values, setValues, onInputChange, onSubmit };
};

export default useForm;
