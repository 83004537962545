import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const ContactMap = () => {
	const mapStyles = {
		height: "50vh",
		width: "100%",
		margin: "0",
	};
	const defaultCenter = {
		lat: 42.144334588762206,
		lng: 24.754071684571727,
		
	};
	const locations = [
		{
		  name: "ilievsoft",
		  location: { 
			lat: 42.144293,
			lng: 24.754147,
		  },
		}]
	
		
			
			
	return (
		
		<LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS}>
			<GoogleMap mapContainerStyle={mapStyles} center={defaultCenter} zoom={6}>
				{locations.map(item => {
              return (
              <Marker key={item.name} position={item.location}/>
              )
            })}
				<></>
			</GoogleMap>
		</LoadScript>
	);
	
};


export default ContactMap;
