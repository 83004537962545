import img1 from '../../../assets/img/work-process/1.png'
import img2 from '../../../assets/img/work-process/2.png'
import img3 from '../../../assets/img/work-process/3.png'

export const WorkProcessData =[
{
 img:img1,
 heading:"homepage.working-progress.reliable",
 para:"homepage.working-progress.the-most",
 
 button:"homepage.working-progress.button",
 readmore:"/about"
},
{
 img:img2,
 heading:"homepage.working-progress.professional",
 
 para: "homepage.working-progress.our-main" ,
 
 button:"homepage.working-progress.button" ,
 
 readmore:"/about",
 
},
{
 img:img3,
 heading:"homepage.working-progress.design",
 para:"homepage.working-progress.our-highest",
 button:"homepage.working-progress.button",
 readmore:"/about"
}
]
