import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function OurServiceCard(props) {
	const [servicesList, setServicesList] = useState([]);
	const { i18n, t } = useTranslation(["header"]);

	return (
		<>
			{/* Service Card Start */}
			<div className="col-lg-4 col-md-6 col-sm-12 col-12 ">
				<div className="service-box animation-translate box-shadow border-radius">
					<div className="service-box-inner text-center">
						<img alt="" src={props.data.img} />
						<h3>
							<Link to="/service_details">{t(props.data.heading)}</Link>
						</h3>
						<p></p>
						<Link to="/service_details">{t(props.data.paragraph)}</Link>
					</div>
				</div>
			</div>
			{/* Service Card End */}
		</>
	);
}

export default OurServiceCard;
