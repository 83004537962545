import React from 'react'
import img1 from '../../assets/img/service-details/1.jpg'
import {BsCheckCircle} from "react-icons/bs";

function ServiceGraph() {
    return (
        <>
            <section id="service-img-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="service-img-details">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="service-img-text">
                                <h3>Technologies that we use</h3>
                               
                                <ul>
                                    <li><i><BsCheckCircle/></i>Salesforce administration and configuration</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Aura Lightning Components</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Lightning Web Components</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Lightning Design Systems</li>
                                    <li><i><BsCheckCircle/></i>Salesforce APEX</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="service-img-text">
                                <h3>Technologies that we use</h3>
                            
                                <ul>
                                    <li><i><BsCheckCircle/></i>Salesforce Sales Cloud</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Commerce Cloud</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Experience Cloud</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Billing</li>
                                    <li><i><BsCheckCircle/></i>Salesforce CPQ</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="service-img-text">
                                <h3>Technologies that we use</h3>
                               
                                <ul>
                                    <li><i><BsCheckCircle/></i>Salesforce Certified Architects</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Certified Developers</li>
                                    <li><i><BsCheckCircle/></i>Salesforce Certified Administrators</li>
                    
                                </ul>
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default ServiceGraph
