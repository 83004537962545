import React from "react";
// import img1 from "../../../assets/img/recent-work/1.png";
// import img2 from "../../../assets/img/recent-work/2.png";
// import img4 from "../../../assets/img/recent-work/4.png";
// import img5 from "../../../assets/img/recent-work/5.png";

import styled from "styled-components";

import Masonry from "react-masonry-css";
import WorkItem from "./WorkItem";
import projects from "../../../component/Projects/ProjectData";
import { useTranslation } from "react-i18next";

const Project = (props) => {
	const { i18n, t } = useTranslation(["header"]);
	const breakpointColumnsObj = {
		default: 3,
		1100: 3,
		700: 2,
		500: 1,
	};
	return (
		<>
			<ProjectSection id="recent-work" className="py100 bg-color">
				<div className=" container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="left-side-title">
								<h3>{t("homepage.projects.recent-projects")}</h3>
								<h2 className="pt10">{t("homepage.projects.check-some")}</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-12">
							<Masonry
								breakpointCols={breakpointColumnsObj}
								className="my-masonry-grid"
								columnClassName="my-masonry-grid_column"
							>
								{projects.map((pr, i) => (
									<WorkItem key={i} {...pr} />
								))}
							</Masonry>
						</div>
					</div>
				</div>
			</ProjectSection>
		</>
	);
};

const ProjectSection = styled.section`
	/* .img-570-240 {
		aspect-ratio: 19 / 8;
		object-fit: cover;
	}

	.img-270-240 {
		aspect-ratio: 9 / 8;
		object-fit: cover;
	} */
`;

export default Project;
