import React from "react";
import { FiChevronDown } from "react-icons/fi";

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
const NavItem = (props) => {

	const { i18n, t } = useTranslation(["header"]);
	return (
		<>
			<li className="nav-item" onClick={props.click}>
				<Link to={props.item.href} className="nav-link">
					{t(props.item.name)}
					{props.item.has_children && (
						<i>
							<FiChevronDown />
						</i>
					)}
				</Link>
				{props.item.has_children && (
					<ul className="dropdown-menu">
						{props.item.children.map((item, index) => (
							<NavItem item={item} key={t(index)} />
						))}
					</ul>
				)}
			</li>
		</>
	);
};

export default NavItem;
