import  React,{useState,useEffect } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import SideBar from "../ProjectDetails/Sidebar";
import img1 from "../../assets/img/project-details/1.jpg";
import img2 from "../../assets/img/project-details/2.png";
import leica from "../../assets/img/leica.jpg";
import llogoleica from "../../assets/img/llogoleica.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import projectsList from "../Projects/ProjectData";

const SingleProject = () => {
	const { i18n, t } = useTranslation(["header"])
	const { id } = useParams();

	const [projectToDisplay, setProjectToDisplay] = useState({});
	const languages = [
		{ name: "en" },
		{ name: "bg"},]

	useEffect(() => {
		const current = projectsList.find((el) => el.id === id);
		setProjectToDisplay((prev) => ({ ...current }));
		i18n.changeLanguage(localStorage.getItem("i18nextLng"));
	}, []);
	
	 if(projectToDisplay.id == 'zonia')
	{ 
		return(
		<>
			<section id="project-details-area" className="py100">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 col-sm-12 col-12">
							<div className="project-desc">
								<div className="project-details-text">
									<img alt="" src={projectToDisplay.img} />
									<h1>{projectToDisplay.title}</h1>
									<h3>{t("projects.project-description")}</h3>
									<p>
									{t(projectToDisplay.discovered)}
									</p>
									<blockquote className="box-shadow">
									{t(projectToDisplay.quote)}
									</blockquote>
									<p>
									{t(projectToDisplay.secondp)}
									</p>
								</div>
								<div className="project-details-bottom">
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="pro-des-img">
												<img alt="" src={projectToDisplay.img2} />
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="pro-deta-list">
												<ul>
													
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a1)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a2)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a3)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a4)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a5)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														
														{t(projectToDisplay.a6)}
													</li>
													</ul>
												
														
														<ul>
													<><li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a7)}
													</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a8)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a9)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a10)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a11)}
														</li>
															
														</>
												</ul>

												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 col-12">
							<SideBar />
						</div>
					</div>
				</div>
			</section>
		</>
		);
	}
	else if(projectToDisplay.id == 'boosting-factory')
	{ 
		return(
		<>
			<section id="project-details-area" className="py100">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 col-sm-12 col-12">
							<div className="project-desc">
								<div className="project-details-text">
									<img alt="" src={projectToDisplay.img} />
									<h1>{projectToDisplay.title}</h1>
									<h3>{t("projects.project-description")}</h3>
									<p>
									{t(projectToDisplay.discovered)}
									</p>
									<blockquote className="box-shadow">
									{t(projectToDisplay.quote)}
									</blockquote>
									<p>
									{t(projectToDisplay.secondp)}
									</p>
								</div>
								<div className="project-details-bottom">
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="pro-des-img">
												<img alt="" src={projectToDisplay.img2} />
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="pro-deta-list">
												<ul>
													
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a1)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a2)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a3)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a4)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a5)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														
														{t(projectToDisplay.a6)}
													</li>
													</ul>
												
														
														<ul>
													<><li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a7)}
													</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a8)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a9)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a10)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a11)}
														</li><li>
															<i>
																<AiOutlineCheckCircle />
															</i>{" "}
															{t(projectToDisplay.a12)}
														</li></>
												</ul>

												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 col-12">
							<SideBar />
						</div>
					</div>
				</div>
			</section>
		</>
		);
	}
	else 
	{
	return (
		
		<>
			<section id="project-details-area" className="py100">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 col-sm-12 col-12">
							<div className="project-desc">
								<div className="project-details-text">
									<img alt="" src={projectToDisplay.img} />
									<h1>{projectToDisplay.title}</h1>
									<h3>{t("projects.project-description")}</h3>
									<p>
									{t(projectToDisplay.discovered)}
									</p>
									<blockquote className="box-shadow">
									{t(projectToDisplay.quote)}
									</blockquote>
									<p>
									{t(projectToDisplay.secondp)}
									</p>
								</div>
								<div className="project-details-bottom">
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="pro-des-img">
												<img alt="" src={projectToDisplay.img2} />
											</div>
										</div>
										<div className="col-lg-6 col-md-6 col-sm-12 col-12">
											<div className="pro-deta-list">
												<ul>
													
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a1)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a2)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a3)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a4)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														{t(projectToDisplay.a5)}
													</li>
													<li>
														<i>
															<AiOutlineCheckCircle />
														</i>{" "}
														
														{t(projectToDisplay.a6)}
													</li>
													</ul>
												
														
													

												
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 col-12">
							<SideBar />
						</div>
					</div>
				</div>
			</section>
		</>
	);
	}
};

export default SingleProject;
