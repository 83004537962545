import React from 'react'
// import About Img
import img1 from '../../assets/img/logo830.png'
// import Icon
import {FiCheck} from 'react-icons/fi';
import { useTranslation } from "react-i18next";
const History = () => {
    const { i18n, t } = useTranslation(["header"]);
    return (
        <>
            {/* About Top Area Start */}
            <section id="about-top" className="py100 bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about-img">
                                <h2> {t("homepage.history.title")}</h2>
                                <p>
                                {t("homepage.history.p1")} 
                                </p>
                                <p>
                                {t("homepage.history.p2")}
                                 </p>
                                <p>
                                {t("homepage.history.p3")} 
                                </p>
                                <p>
                                {t("homepage.history.p4")}
                                </p>
                                <p>
                                {t("homepage.history.p5")} 
                                </p>
                                <ul>
                                    <li><i><FiCheck/></i>{t("homepage.history.check1")} </li>
                                    <li>
                                        <i><FiCheck/></i>{t("homepage.history.check2")}
                                    </li>
                                    <li><i><FiCheck/></i>{t("homepage.history.check3")}</li>
                                    <li>
                                        <i><FiCheck/></i>{t("homepage.history.check4")}
                                    </li>
                                    <li><i><FiCheck/></i>{t("homepage.history.check5")}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about-top-img animation-img-two">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* About Top Area Start */}
        </>
    )
}

export default History
