import React, { useRef, useState, useEffect, useCallback } from "react";
import FormInput from "../From/index";

import emailjs from "@emailjs/browser";
import {
	GoogleReCaptchaProvider,
	useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

import useForm from "../../hooks/useForm";

import styled from "styled-components";
import { useTranslation } from "react-i18next";
const ContactForm = () => {
	const { i18n, t } = useTranslation(["header"]);
	const form = useRef();

	const { executeRecaptcha } = useGoogleReCaptcha();

	const { values, onInputChange, onSubmit } = useForm(sendEmail, {
		first_name: "",
		last_name: "",
		subject: "",
		email: "",
		message: "",
	});

	function sendEmail(e) {
		emailjs
			.sendForm(
				process.env.REACT_APP_MAILJS_SERVICE_ID,
				process.env.REACT_APP_MAILJS_TEMPLATE_ID,
				form.current,
				process.env.REACT_APP_MAILJS_PUBLIC_KEY
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);
	}

	// Create an event handler so you can call the verification on button click event or form submit
	const handleReCaptchaVerify = useCallback(async () => {
		if (!executeRecaptcha) {
			console.log("Execute recaptcha not yet available");
			return;
		}

		const token = await executeRecaptcha("yourAction");
		// Do whatever you want with the token
	}, [executeRecaptcha]);

	// You can use useEffect to trigger the verification as soon as the component being loaded
	useEffect(() => {
		handleReCaptchaVerify();
	}, [handleReCaptchaVerify]);

	return (
		<div className="contact-form-area box-shadow">
			<form ref={form} onSubmit={onSubmit}>
				<div className="row">
					<div className="col-lg-6  col-md-12 col-sm-12 col-12">
						<FormInput
							tag={"input"}
							type={"text"}
							name={"first_name"}
							classes={"form-control"}
							placeholder={t("homepage.contactform.first")}
							value={values.first_name}
							onChange={onInputChange}
						/>
					</div>
					<div className="col-lg-6  col-md-12 col-sm-12 col-12">
						<FormInput
							tag={"input"}
							type={"text"}
							name={"last_name"}
							classes={"form-control"}
							placeholder={t("homepage.contactform.last")}
							value={values.last_name}
							onChange={onInputChange}
						/>
					</div>
					<div className="col-lg-12  col-md-12 col-sm-12 col-12">
						<FormInput
							tag={"input"}
							type={"text"}
							name={"subject"}
							classes={"form-control"}
							placeholder={t("homepage.contactform.subject")}
							value={values.subject}
							onChange={onInputChange}
						/>
					</div>
					<div className="col-lg-12  col-md-12 col-sm-12 col-12">
						<div className="form-group">
							<FormInput
								tag={"input"}
								type={"text"}
								name={"email"}
								classes={"form-control"}
								placeholder={t("homepage.contactform.enter")}
								value={values.email}
								onChange={onInputChange}
							/>
						</div>
					</div>
					<div className="col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="form-group">
							<FormInput
								tag={"textarea"}
								name={"message"}
								classes={"form-control"}
								placeholder={t("homepage.contactform.write")}
								value={values.message}
								onChange={onInputChange}
							/>
						</div>
					</div>

					<div className="col-lg-12 col-md-12 col-sm-12 col-12">
						<div className="form-submit-button" onClick={handleReCaptchaVerify}>
							<FormInput tag={"button"} val={t("homepage.contactform.send")} />
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

const CaptchaWrapper = () => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_KEY}>
			<ContactForm />
		</GoogleReCaptchaProvider>
	);
};

const CaptchaWrapperEl = styled.div`
	margin-bottom: 10px;
	opacity: ${({ captcha }) => (captcha ? 1 : 0)};
`;

export default CaptchaWrapper;
