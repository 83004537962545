import React ,{useState} from "react";
import { useTranslation } from "react-i18next";

 export default function Login() {
	const { i18n, t } = useTranslation(["header"]);
    const [disable, setDisable] = useState(true);

    function handleChange(event) {
        setDisable(event.target.value === '');
    }
 

//const Cta = () => {
    
    return (
       
       <>
       
            <section id="cta-area" className="py100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="cta-search-area">
                                <div className="input-group">
                                <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
      <form onsubmit="return validateForm()" 
      action="https://app.convertkit.com/forms/3434164/subscriptions"
      
 
 method="post"
 data-sv-form="3434164" 
data-uid="54282d4213"
 data-format="inline" 
data-version="5" 
data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;redirect&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;/thank-you&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}" min-width="400 500 600 700 800">
<div data-style="clean">
<ul  data-element="errors" data-group="alert"></ul>
<div data-element="fields" data-stacked="false" >
<div  class="containers">
    <input onChange={handleChange} className="form-control" name="email_address" aria-label="Email Address" placeholder={t("homepage.newsletter.placeholder")} required="" type="email"  />
<div className="input-group-append">
<button disabled={disable} className="btn btn-cta" data-element="submit" >
<span >{t("homepage.newsletter.button")}</span></button>
</div><div ></div></div></div></div></form>
                                   
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="news-letter-text pl40 pr80 responsive-mt-30">
                                <h3 className="white-color">{t("homepage.newsletter.title")}</h3>
                                <h2 className="white-color">{t("homepage.newsletter.subscribe")}</h2>
                                <p className="white-color">
                                {t("homepage.newsletter.fillin")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}


//export default Cta


