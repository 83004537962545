import React from "react";
import ReactDOM from "react-dom";

// App Js
import App from "./App";

// Style Css
import "./assets/css/style.css";
import "./assets/css/color.css";
import "./assets/css/shadows.css";
import "./assets/css/responsive.css";

import "./i18n";

ReactDOM.render(
	<React.Fragment>
		<App />
	</React.Fragment>,
	document.getElementById("root")
);
