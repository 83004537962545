import React from 'react'
import img1 from '../../assets/img/service-details/1.jpg'
import {BsCheckCircle} from "react-icons/bs";

function WebDevelopmentGraph() {
    return (
        <>
            <section id="service-img-details" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="service-img-details">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                        
                        
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="service-img-text">
                                <h3>Technologies that we use</h3>
                               
                                <ul>
                                    <li><i><BsCheckCircle/></i>PHP with Laravel</li>
                                    <li><i><BsCheckCircle/></i>Symphony</li>
                                    <li><i><BsCheckCircle/></i>CakePHP and DOCTRINE frameworks</li>
                                    <li><i><BsCheckCircle/></i>Node</li>
                                    <li><i><BsCheckCircle/></i>React</li>
                                    <li><i><BsCheckCircle/></i>HTML</li>
                                    <li><i><BsCheckCircle/></i>CSS</li>
                    
                                </ul>
                                <h3>Technologies that we use</h3>
                               
                                <ul>
                                    <li><i><BsCheckCircle/></i>PosgreSQL</li>
                                    <li><i><BsCheckCircle/></i>MySQL</li>
                                    <li><i><BsCheckCircle/></i>MongoDB</li>
                    
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                </div>
            </section>
        </>
    )
}

export default WebDevelopmentGraph
