import React from 'react'
import img1 from '../../../assets/img/project-details/2.png'
import {Link} from 'react-router-dom'
import { useTranslation } from "react-i18next";
const Index = () => {
    const { i18n, t } = useTranslation(["header"]);
    return (
        <>
            <section id="service-dec" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="service-dec-right-side">
                                <div className="left-side-title">
                                    <h3> {t("homepage.contact.title")} </h3>
                                    <h2 className="pt10">
                                    {t("homepage.contact.are")} 
                                    </h2>
                                </div>
                                <div className="service-dec-left-text">
                                    <p>{t("homepage.contact.interested")}
                                    </p> <Link to="/projects" className="btn btn-theme">{t("homepage.contact.projects")}</Link>
                                    <Link to="/contact" className="btn btn-theme">{t("homepage.contact.contact")}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Index
