import React from 'react'
import {Link} from 'react-router-dom'
import img2 from '../assets/img/thanks.png'
import { useTranslation } from "react-i18next";

const Thanks = () => {
    const { i18n, t } = useTranslation(["header"]);
    return (
        <>
            <section id="thanks" className="py100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="section-title-center">
                                <h2 className="pb10">{t("homepage.thanks.message")}</h2>
                                <p className="pb20"></p>
                                <Link to="/" className="btn btn-theme">{t("homepage.thanks.back")}</Link>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="error-img animation-img-two">
                            <img alt="" src={img2} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Thanks
