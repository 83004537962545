import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import logos from "../assets/img/logo.png";
import logos from "../assets/img/custom/iliev_soft_logо.png";
import { HiMenuAlt3 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import { MenuData } from "./MenuData";
import NavItem from "./NavItems";

import styled from "styled-components";

import frFlag from "../assets/img/custom/flags/france.png";
import ukFlag from "../assets/img/custom/flags/uk.png";
import bgFlag from "../assets/img/custom/flags/bulgaria.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";

const languages = [
	{ name: "en", flag: ukFlag },
	{ name: "fr", flag: frFlag },
	{ name: "bg", flag: bgFlag },
];

const Header = () => {
	/* languages custom select */

	/* set the namespace */
	const { i18n, t } = useTranslation(["header"]);

	const [language, setLanguage] = useState({
		name: "en",
		flag: ukFlag,
	});

	const handleLanguageChoise = (e) => {
		const neededLanguage = languages.find(
			(el) => el.name === e.currentTarget.dataset.name
		);

		setLanguage(neededLanguage);
		i18n.changeLanguage(e.currentTarget.dataset.name);
	};

	/* if dropdown */
	// const [languageSelectClick, setLanguageSelectClick] = useState(false);
	// const onLanguagesSelectClick = (e) => {
	// 	e.preventDefault();

	// 	setLanguageSelectClick(!languageSelectClick);
	// };

	/* end languages custom select */

	// Sticky Menu Area
	useEffect(() => {
		window.addEventListener("scroll", isSticky);
		return () => {
			window.removeEventListener("scroll", isSticky);
		};
	});

	// we need this because by default i18next sets localStorage as: i18nextLng: en-US
	useEffect(() => {
		if (localStorage.getItem("i18nextLng")?.length > 2) {
			i18next.changeLanguage("en");
		}
	}, []);

	const isSticky = (e) => {
		const header = document.querySelector(".navbar-area");
		const scrollTop = window.scrollY;
		scrollTop >= 250
			? header.classList.add("is-sticky")
			: header.classList.remove("is-sticky");
	};

	const [click, setClick] = useState(false);

	const handleClick = () => {
		if (click) {
			document
				.querySelector("#navbarSupportedContent")
				.classList.remove("d-block");
		} else {
			document
				.querySelector("#navbarSupportedContent")
				.classList.add("d-block");
		}
		setClick(!click);
	};

	return (
		<>
			<div className="navbar-area">
				<div className="plamb-nav">
					<div className="container">
						<nav className="navbar navbar-expand-md navbar-light">
							<Link className="navbar-brand" to="/">
								<img src={logos} className="white-logo" alt="logo" />
								<img src={logos} className="black-logo" alt="logo" />
							</Link>

							<div className="mean-menu" id="navbarSupportedContent">
								<ul className="navbar-nav">
									{MenuData.map((item, index) => (

										<NavItem item={item} key={index} />
									))}
								</ul>

								<SelectLanguages>
									<div
										className="language-wrapper"
										data-name="en"
										onClick={handleLanguageChoise}
									>
										<img src={ukFlag} alt="" />
									</div>

									<div
										className="language-wrapper"
										data-name="bg"
										onClick={handleLanguageChoise}
									>
										<img src={bgFlag} alt="" />
									</div>

									{/* if dropdown */}
									{/* {languageSelectClick && (
										<LanguageOptions
											language={language}
											handleLanguageChoise={handleLanguageChoise}
										/>
									)} */}
								</SelectLanguages>

								<div className="other-option">
									<Link className="btn btn-theme" to="/Contact">
										{t("header.contact")}
									</Link>
								</div>
							</div>
						</nav>
					</div>
				</div>
				<div className="plamb-responsive-nav">
					<div className="container">
						<div className="responsive-button" onClick={handleClick}>
							{click ? <AiOutlineClose /> : <HiMenuAlt3 />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const SelectLanguages = styled.div`
	margin-right: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	align-self: center;
	font-size: 14px;
	font-weight: 600;
	padding: 5px;
	position: relative;
	min-width: 80px;

	.language-wrapper {
		margin-right: 10px;
		border: 0px;
		padding: 5px;

		span {
			margin-right: 5px;
		}
	}

	img {
		width: 30px;
		height: 15px;
		object-fit: cover;
	}

	@media (max-width: 576px) {
		justify-content: center;
	}

	/* if dropdown */
	/* .options-wrapper {
		position: absolute;
		display: flex;
		flex-direction: column;
		bottom: -50px;
		font-size: 12px;
		left: 0;
		width: 100%;
		padding: 5px;
		background: #fff;
		border-radius: 3px;
		box-shadow: var(--greyOne);

		.inner-language-wrapper {
			display: flex;
			justify-content: space-between;
			margin-bottom: 5px;

			&:last-of-type {
				margin-bottom: 0;
			}

			img {
				width: 30px;
				height: 15px;
				object-fit: cover;
			}
		}
	} */
`;

// if dropdown

// const LanguageOptions = ({ language, handleLanguageChoise }) => {
// 	return (
// 		<div className="options-wrapper">
// 			{languages
// 				.filter((el) => el.name !== language.name)
// 				.map((el, i) => (
// 					<div
// 						key={i}
// 						className="inner-language-wrapper"
// 						data-name={el.name}
// 						onClick={handleLanguageChoise}
// 					>
// 						<span>{el.name}</span>
// 						<img src={el.flag} alt="" />
// 					</div>
// 				))}
// 		</div>
// 	);
// };

export default Header;
