import React from "react";
import CommonBanner from "../component/Common/Banner";
import OurProjects from "../component/Projects";

const Projects = () => {
	return (
		<>
			<CommonBanner heading="Our Projects" menu1="Home" menu2="Our Projects" />
			<OurProjects />
		</>
	);
};

export default Projects;
