import React from "react";

//  Import Img
import check from "../../../assets/img/about/tick.png";
import newsalesforce from "../../../assets/img/newsalesforce.png";
import AboutImg from "../../../assets/img/about/1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const languages = [{ name: "en" }, { name: "bg" }];

const AboutOne = () => {
	const { i18n, t } = useTranslation(["header"]);

	return (
		<>
		
			{/* About One Area Start */}
			<section id="about-area-two" className="py100" >
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="left-side-title">
								<h3>{t("homepage.salesforce.saleforce")}</h3>
								<h2 className="pt10">
									{t("homepage.salesforce.saleforce-development")}
								</h2>
							</div>
							<div className="about-details pt10">
								<p>{t("homepage.salesforce.wide-range")}</p>
								<ul className="pt20">
									<li className="wow zoomIn" data-wow-duration="2.0s">
										<img alt="" src={check} />
										Sales Cloud
									</li>
									<li className="wow zoomIn" data-wow-duration="2.0s">
										<img alt="" src={check} />
										Experience Cloud
									</li>
									<li className="wow zoomIn" data-wow-duration="2.0s">
										<img alt="" src={check} />
										Salesforce Commerce Cloud
									</li>
									<li className="wow zoomIn" data-wow-duration="2.0s">
										<img alt="" src={check} />
										Salesforce CPQ
									</li>
								</ul>
								<a
									href="/service_details/salesforce"
									className="btn btn-theme mt30"
								>
									{t("homepage.salesforce.read-more")}
								</a>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 col-sm-12 col-12">
							<div className="animation-img animation-img-one">
								<img
									className="newsalesforce"
									src={newsalesforce}
									alt="newsalesforce"
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* About One Area End */}
		</>
	);
};

export default AboutOne;
