import React, { useState, useEffect } from "react";
import CommonBanner from "../component/Common/Banner";
//import SingleServiceDetails from "../component/ServiceDetails/SingleServiceDeatils";
import SingleWebDevelopmentDetails from "../component/ServiceDetails/SingleWebDevelopmentDeatils";
import RelatedServices from "../component/ServiceDetails/RelatedServices";
//import ServiceGraph from "../component/ServiceDetails/ServiceGraph";
import WebDevelopmentGraph from "../component/ServiceDetails/WebDevelopmentGraph";
import OurServices from "../component/Home/OurService/OurServices";
import { servicesInfo } from "../assets/img/custom/js/servicesInfo";

const WebDevelopmentDetails = (props) => {
	const serviceToRender = props.match.params.service;
	const [service, setService] = useState({});

	useEffect(() => {
		const currentService = servicesInfo.find(
			(el) => el.type === serviceToRender
		);

		setService(() => currentService);
	}, [serviceToRender]);

	return (
		<>
			<CommonBanner
				heading={WebDevelopmentDetails.title}
				menu1="Home"
				menu2="Service Details"
			/>
			<SingleWebDevelopmentDetails />
			<OurServices />
			
			<WebDevelopmentGraph />
			
		
		</>
	);
};

export default WebDevelopmentDetails;
