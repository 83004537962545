import React from "react";
// Import Components
import SectionHeading from "../SectionHeading/SectionHeading";
import WorkingProcessCard from "../OurWorkingProcess/WorkingProcessCard";
import { WorkProcessData } from "./WorkingProcessData";
import { useTranslation } from "react-i18next";

const WorkingProcess = ({ className = "" }) => {
	const { i18n, t } = useTranslation(["header"]);
	return (
		<>
			{/* Work Process Area Start */}
			<section id="work-process" className={`py100 ${className}`}>
				<div className="container">
					<SectionHeading
						title={t("homepage.working-progress.our-working-process")}
						heading={t("homepage.working-progress.data-science-solutions")}
					/>
					<div className="row">
						{WorkProcessData.map((data, index) => (
							<WorkingProcessCard data={data} key={index} />
						))}
					</div>
				</div>
			</section>
		</>
	);
};

export default WorkingProcess;
