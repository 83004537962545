export const servicesInfo = [
	{
		type: "salesforce",
		title: "Salesforce",
		description: "...some text for Salesforce description",
	},
	{
		type: "web-development",
		title: "Web Development",
		description: "...some text for Web Development description",
	},
];
