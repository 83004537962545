import React from "react";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";
import styled from "styled-components";

import projectsBanner from "../../../assets/img/custom/projects-page/projects-banner.jpg";

const CommonBanner = (props) => {
	return (
		<CustomBanner
			id="banner-inner-area"
			initial={{ boxShadow: "none" }}
			animate={{
				boxShadow: "var(--blueOne)",
			}}
			transition={{
				when: "afterChildren",
				duration: 2,
			}}
		>
			<div
				className="container"
				style={{
					display: "flex",
				}}
			>
				<div
					className="row"
					style={{
						flex: 1,
					}}
				>
					<div
						className="col-lg-12"
						style={{
							display: "flex",
						}}
					>
						<div
							className="banner-details text-center"
							style={{
								display: "flex",
								flexGrow: 1,
								flexDirection: "column",
								justifyContent: "center",
							}}
						>
							<h2>{props.heading}</h2>
							<ul>
								<li>
									<Link to="/">{props.menu1}</Link>
								</li>
								<li>
									<span>/</span>
									{props.menu2}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<ImgWrapper>
				<Img
					src={projectsBanner}
					initial={{
						opacity: 0,
						filter: "blur(14px)",
					}}
					animate={{
						opacity: 1,
						filter: "blur(2px)",
					}}
					transition={{
						duration: 2,
					}}
				/>
			</ImgWrapper>
		</CustomBanner>
	);
};

const CustomBanner = styled(motion.section)`
	height: 30vh;
	width: 100%;
	display: flex;
	position: relative;

	& .banner-details {
		display: flex;
		flex-direction: column;
	}
`;

const ImgWrapper = styled(motion.div)`
	display: flex;
	flex-direction: column;
	position: absolute;
	justify-content: flex-end;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
`;

const Img = styled(motion.img)`
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export default CommonBanner;
