export const MenuData = [
	{
		name: "header.menu.home-link",
		href: "/",
		has_children: false,
	},
	{
		name: "header.menu.services-link",
		href: "#!",
		has_children: true,
		children: [
			{
				name: "header.menu.salesforce-link",
				// href: "/services",
				href: "/service_details/salesforce",
				has_children: false,
			},
			{
				name: "header.menu.web-development-link",
				// href: "/service_details",
				href: "/service_details/web-development",
				has_children: false,
			},
		],
	},
	{
		name: "header.menu.about-us-link",
		href: "/about",
		has_children: false,
	},
	{
		name: "header.menu.projects-link",
		href: "/projects",
		has_children: false,
		children: [
			{
				name: "Projects",
				href: "/projects",
				has_children: false,
			},
			{
				name: "Project Details",
				href: "/project_details",
				has_children: false,
			},
		],
	},
	// {
	// 	name: "Blog",
	// 	href: "#!",
	// 	has_children: true,
	// 	children: [
	// 		{
	// 			name: " Blog",
	// 			href: "/blog",
	// 			has_children: false,
	// 		},
	// 		{
	// 			name: "Blog Details",
	// 			href: "/blog_details",
	// 			has_children: false,
	// 		},
	// 	],
	// },
	// {
	//     name: "Pages",
	//     href: "#!",
	//     has_children: true,
	//     children: [
	//         {
	//             name: "Shop",
	//             href: "/shop",
	//             has_children: false,
	//         },
	//         {
	//             name: "Single Product",
	//             href: "/product_details",
	//             has_children: false,
	//         },
	//         {
	//             name: "Cart",
	//             href: "/cart",
	//             has_children: false,
	//         },
	//         {
	//             name: "Checkout",
	//             href: "/checkout",
	//             has_children: false,
	//         },
	//         {
	//             name: "Login",
	//             href: "/login",
	//             has_children: false,
	//         },
	//         {
	//             name: "Contact Us",
	//             href: "/contact",
	//             has_children: false,
	//         },
	//         {
	//             name: "FAQ",
	//             href: "/faqs",
	//             has_children: false,
	//         },
	//         {
	//             name: "404 Error",
	//             href: "/error",
	//             has_children: false,
	//         }
	//     ]
	// }
];
