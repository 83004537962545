import React, { useState, useEffect } from "react";
import {FaFacebookF} from 'react-icons/fa';
import {AiOutlineTwitter} from 'react-icons/ai';
import {FaLinkedinIn} from 'react-icons/fa';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const CopyRight = () => {
    const { i18n, t } = useTranslation(["header"])
    return (
        <>
        
            <div className="copy-right">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="copy-text">
                                
                                <p>{t("footer.iliev-soft")}© {(new Date().getFullYear())}{t("footer.rights-reserved")}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="copy-icon text-lg-right">
                                <ul>
                                    <li><a href="https://www.facebook.com/ilievsoft"target="_blank"><i><FaFacebookF/></i></a></li>                                    
                                    <li><a href="https://www.linkedin.com/company/ilievsoft"target="_blank"><i><FaLinkedinIn/></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CopyRight
