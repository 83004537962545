import React from 'react'
import LoginForm from '../component/Login'

const Login = () => {
    return (
        <>
            <LoginForm/>
        </>
    )
}

export default Login
