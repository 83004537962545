import img1 from '../../assets/img/blog/post1.jpg'


export const BlogData = [
    {
        img: img1,
        heading: "Check out the best themes of the last year",
        comment: 24,
        view: 20,
        category: "Software",
        date: "March 14th 2020",
        para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel mollitia eos a.Laborum reprehenderit animi magnam ipsam tenetur natus, similique earum cum, ut
 fugit distinctio dolores ad corporis doloribus consectetur.
 Lorem ipsum dolor sit, amet consectetur adipisicing elit.`,
        button: "Read More"
    },
    {
        img: img1,
        heading: "Check out the best themes of the last year",
        comment: 24,
        view: 20,
        category: "Software",
        date: "March 14th 2020",
        para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel mollitia eos a.Laborum reprehenderit animi magnam ipsam tenetur natus, similique earum cum, ut
 fugit distinctio dolores ad corporis doloribus consectetur.
 Lorem ipsum dolor sit, amet consectetur adipisicing elit.`,
        button: "Read More"
    },
    {
        img: img1,
        heading: "Check out the best themes of the last year",
        comment: 24,
        view: 20,
        category: "Software",
        date: "March 14th 2020",
        para: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel mollitia eos a.Laborum reprehenderit animi magnam ipsam tenetur natus, similique earum cum, ut
 fugit distinctio dolores ad corporis doloribus consectetur.
 Lorem ipsum dolor sit.`,
        button: "Read More"
    }
]

